import * as React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import BankOfCoresPage from "./components/pages/bankOfCores/BankOfCoresPage";
import TopBar from "./components/pages/layout/TopBar";
import LoginKeyPage from "./components/pages/login/LoginKeyPage";
import LoginEmailPage from "./components/pages/login/LoginEmailPage";
import LoginLinkPage from "./components/pages/login/LoginLinkPage";
import { Redirect } from "react-router";
import ApiPage from "./components/pages/api/ApiPage";
import { ROUTES } from "./utils/Routes";

const App = () => {
    return (
        <TopBar>
            <Switch>
                <Route exact path={ROUTES.root}>
                    <Redirect to={ROUTES.loginKey} />;
                </Route>
                <Route exact path={ROUTES.api} component={ApiPage} />
                <Route exact path={ROUTES.loginKey} component={LoginKeyPage} />
                <Route exact path={ROUTES.main} component={BankOfCoresPage} />
                <Route path={ROUTES.loginEmail} component={LoginEmailPage} />
                <Route path={ROUTES.loginLink} component={LoginLinkPage} />
                <Route component={NotFound} />
            </Switch>
        </TopBar>
    );
};

export default App;
