import { toast, ToastOptions } from "react-toastify";

const defaultToastOptions: ToastOptions = {
    position: "bottom-right",
    theme: "dark",
};

toast.configure();

export class MessagePublisher {
    reportError(error: string, options?: ToastOptions) {
        toast.error(error, { ...defaultToastOptions, ...options, icon: "🚫" });
    }

    reportWarning(warning: string, options?: ToastOptions) {
        toast.warn(warning, { ...defaultToastOptions, ...options, icon: "👀" });
    }

    reportSuccess(message: string, options?: ToastOptions) {
        toast.success(message, { ...defaultToastOptions, ...options, icon: "🙌" });
    }
}

export const messagePublisher = new MessagePublisher();
