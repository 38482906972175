import React, { FormEvent, useState } from "react";
import { ApiKey } from "../../../models/apiKeysModel";
import { useServices } from "../../../hooks/useServices";
import { messagePublisher } from "../../../utils/MessagePublisher";
import moment from "moment";
import { formatServerDate } from "../../../utils/formatHelper";
import { Button, Form, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faPenToSquare, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { exceptionHandler } from "../../../utils/exceptionHandlers";
import DatePicker from "../../ui/DatePicker";
import ManipulationApiKeyConfirmation from "./ManipulationApiKeyConfirmation";

interface Props {
    apiKey: ApiKey;
    refresh: () => Promise<void>;
}

const ApiKeyListItem = ({ apiKey, refresh }: Props) => {
    const { apiKeysService } = useServices();

    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

    const [showDeactivateApiKeyConfirmationModal, setShowDeactivateApiKeyConfirmationModal] = useState<boolean>(false);

    const now = moment();

    const [inputExpirationDateValue, setInputExpirationDateValue] = useState<Date>(moment(apiKey.expiresAt).toDate());
    const [inputDescriptionValue, setInputDescriptionValue] = useState<string>(apiKey.description);

    const editApiKey = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            await apiKeysService.editApiKey(apiKey.id, {
                description: inputDescriptionValue,
                expiresAt: inputExpirationDateValue ? moment(inputExpirationDateValue).format("YYYY-MM-DD") : null,
            });

            messagePublisher.reportSuccess("The API key has been edited successfully.");
        } catch (e) {
            exceptionHandler(e, `Failed to edit API key.`);
            rollbackEditionChanges();
        } finally {
            setInEditMode(false);
            await refresh();
        }
    };

    const deactivateApiKey = async () => {
        setIsDeactivating(true);

        try {
            await apiKeysService.deactivateApiKey(apiKey.id);
            messagePublisher.reportSuccess("The API key has been deactivated successfully.");
        } catch (e) {
            exceptionHandler(e, `Failed to deactivate API key.`);
        } finally {
            setIsDeactivating(false);
            setShowDeactivateApiKeyConfirmationModal(false);
            await refresh();
        }
    };

    const rollbackEditionChanges = () => {
        setInputDescriptionValue(apiKey.description);
        setInputExpirationDateValue(moment(apiKey.expiresAt).toDate());
    };

    const onEditCancel = () => {
        rollbackEditionChanges();
        setInEditMode(false);
    };

    const getTooltipId = (prefix: string): string => `${prefix}-${apiKey.id}`;

    const isExpired = apiKey.status === "Expired";
    const isDeactivated = apiKey.status === "Deactivated";

    return (
        <div className={`api-key-list-item status-${apiKey.status.toLowerCase()}`}>
            {inEditMode && (
                <Form className="edit-api-key-form" onSubmit={editApiKey}>
                    <div className="edit-form-cols">
                        <div>
                            <div className="property-name">Description</div>
                            <input
                                value={inputDescriptionValue}
                                onChange={(e) => setInputDescriptionValue(e.target.value)}
                                type="text"
                                placeholder="Description"
                                maxLength={50}
                            />
                        </div>

                        <div>
                            <div className="property-name">Expiration date</div>
                            <DatePicker
                                required
                                selected={inputExpirationDateValue}
                                onChange={setInputExpirationDateValue}
                                showYearDropdown
                                showMonthDropdown
                                minDate={now.add(1, "day").toDate()}
                                maxDate={now.add(12, "months").subtract(1, "day").toDate()}
                                placeholderText="Expiration date"
                            />
                        </div>
                    </div>
                    <div className="list-item-buttons">
                        <UncontrolledTooltip target={getTooltipId("save-button")}>Save</UncontrolledTooltip>
                        <div className="list-item-button">
                            <Button color="primary" type="submit" id={getTooltipId("save-button")}>
                                <FontAwesomeIcon icon={faSave} />
                            </Button>
                        </div>

                        <UncontrolledTooltip target={getTooltipId("cancel-button")}>Cancel</UncontrolledTooltip>
                        <div className="list-item-button">
                            <Button onClick={onEditCancel} id={getTooltipId("cancel-button")}>
                                <FontAwesomeIcon icon={faCancel} />
                            </Button>
                        </div>
                    </div>
                </Form>
            )}

            {!inEditMode && (
                <>
                    <div className="main-cols">
                        <div>
                            <div className="property-name">Description</div>
                            <div className="property-value">{apiKey.description}</div>
                        </div>

                        <div>
                            <div className="property-name">Expiration date</div>
                            <div className="property-value">{formatServerDate(apiKey.expiresAt, "YYYY-MM-DD")}</div>
                        </div>

                        <div>
                            <div className="property-name">Created at</div>
                            <div className="property-value">{formatServerDate(apiKey.createdAt)}</div>
                        </div>

                        <div>
                            <div className="property-name">Status</div>
                            <div className="property-value">{apiKey.status}</div>
                        </div>

                        <div>
                            <div className="property-name">Last access at</div>
                            <div className="property-value">
                                {apiKey.lastAccessAt ? formatServerDate(apiKey.lastAccessAt) : "N/A"}
                            </div>
                        </div>

                        <div>
                            <div className="property-name">Last access ip</div>
                            <div className="property-value">{apiKey.lastAccessIp ?? "N/A"}</div>
                        </div>
                    </div>

                    <div className="buttons-col list-item-buttons">
                        {!isExpired && !isDeactivated && (
                            <>
                                <UncontrolledTooltip target={getTooltipId("edit-button")}>Edit</UncontrolledTooltip>
                                <div id={getTooltipId("edit-button")} className="list-item-button">
                                    <Button onClick={() => setInEditMode(true)}>
                                        <FontAwesomeIcon icon={faPenToSquare} />
                                    </Button>
                                </div>
                                <UncontrolledTooltip target={getTooltipId("deactivate-button")}>
                                    Deactivate
                                </UncontrolledTooltip>
                                <div id={getTooltipId("deactivate-button")} className="list-item-button">
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowDeactivateApiKeyConfirmationModal(true);
                                        }}
                                        disabled={isDeactivating}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            <ManipulationApiKeyConfirmation
                action="deactivate"
                show={showDeactivateApiKeyConfirmationModal}
                description={inputDescriptionValue}
                cancel={() => setShowDeactivateApiKeyConfirmationModal(false)}
                confirm={deactivateApiKey}
                isManipulating={isDeactivating}
            />
        </div>
    );
};

export default ApiKeyListItem;
