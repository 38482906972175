import React from "react";
import { DescribeBankOfCoresResponse } from "../../../models/bankOfCoresModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faWarning } from "@fortawesome/free-solid-svg-icons";
import { formatServerDate } from "../../../utils/formatHelper";
import { Alert } from "reactstrap";
import { BankOfCoresUtils } from "../../../utils/BankOfCoresUtils";

const filteredFeatures = ["type", "memory", "cores", "totalCores"];

const licenseIcon = require("../../../../img/license-icon.svg");

interface MainValueLabelProps {
    label: string;
    value?: string | number;
}

interface Props {
    bankOfCoresMainLicenseId: string;
    bankOfCoresDto: DescribeBankOfCoresResponse;
    isExpired: boolean;
}

const MainLicense = ({ bankOfCoresDto, bankOfCoresMainLicenseId, isExpired }: Props) => {
    const featuresKeys = Object.keys(bankOfCoresDto.features).filter((x) => !filteredFeatures.includes(x));

    const isDeveloperEdition = BankOfCoresUtils.isDeveloperEdition(bankOfCoresDto);

    return (
        <div className="main-license">
            <div className="main-license-general-container">
                <h3>Main license</h3>
                <div className="main-license-general-body">
                    <div className="license-icon">
                        <img src={licenseIcon} alt="License Icon" />
                    </div>
                    <div className="items">
                        <GeneralValueLabel value={bankOfCoresMainLicenseId} label="Main license id" />
                        <GeneralValueLabel value={bankOfCoresDto.licenseType} label="Type" />
                        <GeneralValueLabel value={bankOfCoresDto.totalCores} label="Total cores" />
                        <GeneralValueLabel value={bankOfCoresDto.remainingCores} label="Remaining cores" />

                        {isDeveloperEdition && (
                            <>
                                {bankOfCoresDto.minCoresPerSublicense && (
                                    <GeneralValueLabel
                                        value={bankOfCoresDto.minCoresPerSublicense}
                                        label="Min cores per license"
                                    />
                                )}

                                {bankOfCoresDto.maxCoresPerSublicense && (
                                    <GeneralValueLabel
                                        value={bankOfCoresDto.maxCoresPerSublicense}
                                        label="Max cores per license"
                                    />
                                )}
                            </>
                        )}

                        <GeneralValueLabel
                            value={formatServerDate(bankOfCoresDto.createdAt, "YYYY-MM-DD")}
                            label="Created at"
                        />
                        <GeneralValueLabel
                            value={formatServerDate(bankOfCoresDto.expirationDate, "YYYY-MM-DD")}
                            label="Expiration date"
                        />
                        <GeneralValueLabel
                            value={
                                bankOfCoresDto.emailNotificationsTo.length > 0
                                    ? bankOfCoresDto.emailNotificationsTo.join(", ")
                                    : null
                            }
                            label="Send notifications to"
                        />

                        {isExpired && (
                            <Alert color="warning" className="mt-4 w-100">
                                <FontAwesomeIcon icon={faWarning} color="warning" />
                                Your main license has expired. Please contact the sales team.
                            </Alert>
                        )}
                    </div>
                </div>
            </div>
            <div className="main-license-features-container">
                <h3>Features</h3>
                <div className="main-license-features-body">
                    {featuresKeys.map((key) => (
                        <FeatureValueLabel key={key} value={bankOfCoresDto.features[key]} label={key} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const GeneralValueLabel = ({ label, value }: MainValueLabelProps) => {
    return (
        <div className="general-item">
            <div className="label">{label}:</div>
            <div className="value">{value ? value : <span className="text-muted">N/A</span>}</div>
        </div>
    );
};

const FeatureValueLabel = ({ label, value }: MainValueLabelProps) => {
    return (
        <div className="feature-item">
            <div className="label">{label}:</div>
            <div className="value">{value ? getFeatureValue(value) : <span className="text-muted">N/A</span>}</div>
        </div>
    );
};

const getFeatureValue = (value: string | number): JSX.Element => {
    if (value === "true") {
        return <FontAwesomeIcon icon={faCircleCheck} />;
    }
    if (value === "false") {
        return <FontAwesomeIcon icon={faCircleXmark} />;
    }

    return <span>{value}</span>;
};

export default MainLicense;
