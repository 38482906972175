import { copyToClipboard } from "../../../utils/CopyToClipboard";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { CreatedApiKey } from "../../../models/apiKeysModel";

interface Props {
    createdApiKey: CreatedApiKey;
    closeModal: () => void;
}

const CreatedApiKeyModal = ({ createdApiKey, closeModal }: Props) => {
    if (!createdApiKey) {
        return null;
    }

    const copyApiKey = () => {
        copyToClipboard(createdApiKey.key, "API key");
    };

    return (
        <Modal isOpen toggle={closeModal}>
            <ModalHeader toggle={closeModal}>Created API key</ModalHeader>

            <ModalBody className="created-api-key-modal-body">
                <pre>{createdApiKey.key}</pre>
                <p className="mt-5">
                    When you <span className="important-text">create</span> an API key, it is displayed{" "}
                    <span className="important-text">only once</span>. After that, you won&apos;t be able to view it
                    again, so it&apos;s essential to <span className="important-text">store it securely</span> right
                    away—like in a password manager or a safe location.
                </p>
                <p>
                    If you <span className="important-text">lose</span> the key, you&apos;ll need to{" "}
                    <span className="important-text">create new one</span>. Always treat your API keys like passwords to
                    protect your applications from unauthorized access.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="info" type="button" onClick={copyApiKey}>
                    <FontAwesomeIcon icon={faClipboard} /> Copy
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CreatedApiKeyModal;
