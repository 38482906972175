import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { stringify } from "qs";

Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export class ApiClient {

    private static async prepareRequest<T>(config: AxiosRequestConfig, relativeUrl: string): Promise<AxiosResponse<T>> {
        const url = this.getApiUrl() + relativeUrl;

        try {
            return await Axios({
                ...config,
                url,
            });
        } catch (e) {
            const errorHandled = ApiClient.tryHandleAxiosError(e);

            if (!errorHandled) {
                throw e;
            }
        }
    }
    
    private static tryHandleAxiosError(error: AxiosError) {
        if (error.response && error.response.status === 401) {
            window.location.href = "/";
            return true;
        }
        return false;
    }

    static getApiUrl() {
        return window.location.origin + /api/;
    }

    static async put<TInput = void, TOutput = void>(relativeUrl: string, payloadData: TInput) {
        return this.prepareRequest<TOutput>(
            {
                method: "put",
                data: payloadData,
            },
            relativeUrl
        );
    }

    static async delete<TOutput = void>(relativeUrl: string) {
        return this.prepareRequest<TOutput>(
            {
                method: "delete",
            },
            relativeUrl
        );
    }

    static async post<TInput = void, TOutput = void>(
        relativeUrl: string,
        payloadData?: TInput
    ): Promise<AxiosResponse<TOutput>> {
        return this.prepareRequest<TOutput>(
            {
                method: "post",
                data: payloadData,
            },
            relativeUrl
        );
    }

    static async query<TInput = void, TOutput = void>(
        relativeUrl: string,
        urlData?: TInput
    ): Promise<AxiosResponse<TOutput>> {
        const paramsSerializer = () => (urlData ? stringify(urlData) : undefined);

        return this.prepareRequest<TOutput>(
            {
                method: "get",
                paramsSerializer,
                params: urlData,
            },
            relativeUrl
        );
    }
}

export abstract class AbstractService {
    protected apiUrl() {
        return "/api/";
    }

    protected async query<TOutput = void>(url: string): Promise<AxiosResponse<TOutput>>;
    protected async query<TInput, TOutput>(url: string, urlData: TInput): Promise<AxiosResponse<TOutput>>;
    protected async query<TInput, TOutput>(url: string, urlData?: TInput): Promise<AxiosResponse<TOutput>> {
        return ApiClient.query<TInput, TOutput>(url, urlData);
    }

    protected async post<TOutput = void>(url: string): Promise<AxiosResponse<TOutput>>;
    protected async post<TInput, TOutput>(url: string, payloadData: TInput): Promise<AxiosResponse<TOutput>>;
    protected async post<TInput, TOutput>(url: string, payloadData?: TInput): Promise<AxiosResponse<TOutput>> {
        return ApiClient.post<TInput, TOutput>(url, payloadData);
    }

    protected async put<TInput>(url: string, payloadData: TInput): Promise<AxiosResponse<void>>;
    protected async put<TInput, TOutput>(url: string, payloadData: TInput): Promise<AxiosResponse<TOutput>> {
        return ApiClient.put<TInput, TOutput>(url, payloadData);
    }

    protected async delete(url: string): Promise<AxiosResponse<void>>;
    protected async delete<TOutput>(url: string): Promise<AxiosResponse<TOutput>> {
        return ApiClient.delete<TOutput>(url);
    }
}
