import { AbstractService } from "../utils/ApiClient";
import { EditApiKeyRequest, CreateApiKeyRequest, CreatedApiKey, GetAllApiKeysResponse } from "../models/apiKeysModel";

export class ApiKeysService extends AbstractService {
    async getAllApiKeys(): Promise<GetAllApiKeysResponse> {
        const response = await this.query<GetAllApiKeysResponse>("api-keys");

        return response.data;
    }

    async createApiKey(createApiKeyRequest: CreateApiKeyRequest): Promise<CreatedApiKey> {
        const response = await this.post<CreateApiKeyRequest, CreatedApiKey>("api-keys", createApiKeyRequest);

        return response.data;
    }

    async editApiKey(id: string, editApiKeyRequest: EditApiKeyRequest): Promise<void> {
        await this.put<EditApiKeyRequest>(`api-keys/${id}`, editApiKeyRequest);
    }

    async deactivateApiKey(id: string): Promise<void> {
        await this.delete(`api-keys/${id}`);
    }
}
