import React from "react";
import ButtonWithSpinner from "../../ui/ButtonWithSpinner";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Props {
    action: "deactivate" | "create";
    show: boolean;
    description: string;
    cancel: () => void;
    confirm: () => void;
    isManipulating: boolean;
}

const ManipulationApiKeyConfirmation = ({ action, show, description, cancel, confirm, isManipulating }: Props) => {
    return (
        <Modal isOpen={show} toggle={isManipulating ? () => {} : cancel}>
            <ModalHeader>
                Are you sure you want to {action === "create" ? "create a new" : "deactivate the"} API key with &quot;
                <strong>{description}</strong>&quot; description?
            </ModalHeader>
            <ModalFooter>
                <Button onClick={cancel} disabled={isManipulating}>
                    Cancel
                </Button>{" "}
                <ButtonWithSpinner
                    type="submit"
                    color="primary"
                    onClick={confirm}
                    disabled={isManipulating}
                    spinning={isManipulating}
                >
                    <FontAwesomeIcon icon={faPlus} className="margin-right-xxs" />{" "}
                    <span className="text-capitalize">{action}</span>
                </ButtonWithSpinner>
            </ModalFooter>
        </Modal>
    );
};

export default ManipulationApiKeyConfirmation;
