import React, { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../../ui/DatePicker";
import { useServices } from "../../../hooks/useServices";
import { exceptionHandler } from "../../../utils/exceptionHandlers";
import { ApiKey, CreatedApiKey } from "../../../models/apiKeysModel";
import moment from "moment";
import CreatedApiKeyModal from "./CreatedApiKeyModal";
import ApiKeysList from "./ApiKeysList";
import Loader from "../../ui/Loader";
import ManipulationApiKeyConfirmation from "./ManipulationApiKeyConfirmation";

const swaggerIcon = require("../../../../img/swagger.svg");

const ApiPage = () => {
    const { apiKeysService } = useServices();

    const now = moment();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isCreatingApiKey, setIsCreatingApiKey] = useState<boolean>(false);
    const [showCreateApiKeyConfirmationModal, setShowCreateApiKeyConfirmationModal] = useState<boolean>(false);
    const [createdApiKey, setCreatedApiKey] = useState<CreatedApiKey>(null);

    const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);

    const [inputExpirationDate, setInputExpirationDate] = useState<Date>(null);
    const [inputDescriptionValue, setInputDescriptionValue] = useState<string>("");

    const clearInputsValues = () => {
        setInputDescriptionValue("");
        setInputExpirationDate(null);
    };

    const createApiKey = async () => {
        setIsCreatingApiKey(true);

        try {
            const result = await apiKeysService.createApiKey({
                description: inputDescriptionValue,
                expiresAt: inputExpirationDate ? moment(inputExpirationDate).format("YYYY-MM-DD") : null,
            });

            setCreatedApiKey(result);
        } catch (e) {
            exceptionHandler(e, "Failed to create API key.");
        } finally {
            setIsCreatingApiKey(false);
            setShowCreateApiKeyConfirmationModal(false);
        }
    };

    const loadInitialData = async () => {
        setIsLoading(true);
        await getAllApiKeys();
        setIsLoading(false);
    };

    const getAllApiKeys = async () => {
        try {
            const result = await apiKeysService.getAllApiKeys();
            setApiKeys(result.apiKeys);
        } catch (e) {
            exceptionHandler(e, "Failed to get all API keys.");
        }
    };

    useEffect(() => {
        (async () => {
            await loadInitialData();
        })();
    }, []);

    return (
        <div className="api-keys-page">
            <p className="api-documentation-description">
                RavenDB Bank Of Cores API is defined using the OpenAPI 3.0 standard.
            </p>

            <div className="api-documentation">
                <Button
                    onClick={() => window.open("https://api.licenses.ravendb.net/swagger/index.html", "_blank")}
                    className="swagger-documentation-button"
                >
                    <img className="swagger-icon" src={swaggerIcon} alt="Swagger Icon" />
                    <p className="swagger-documentation-button-text">Swagger UI</p>
                </Button>

                <Button
                    onClick={() => window.open("https://api.licenses.ravendb.net/swagger/v1/swagger.json", "_blank")}
                    className="swagger-documentation-button"
                >
                    <img className="swagger-icon" src={swaggerIcon} alt="Swagger Icon" />
                    <p className="swagger-documentation-button-text">Swagger JSON</p>
                </Button>
            </div>

            <h1>Manage API Keys</h1>
            <div className="create-api-key-panel">
                <h3>Create new API key</h3>

                <Form
                    className="create-api-key-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        setShowCreateApiKeyConfirmationModal(true);
                    }}
                >
                    <div>
                        <label className="d-block">Description</label>
                        <input
                            value={inputDescriptionValue}
                            onChange={(e) => setInputDescriptionValue(e.target.value)}
                            type="text"
                            placeholder="Description"
                            disabled={isLoading}
                            max={50}
                            required
                        />
                    </div>

                    <div>
                        <label className="d-block">
                            <span>Expiration date</span>
                        </label>
                        <DatePicker
                            required
                            selected={inputExpirationDate}
                            onChange={(x: Date) => setInputExpirationDate(x)}
                            showYearDropdown
                            showMonthDropdown
                            minDate={now.add(1, "day").toDate()}
                            maxDate={now.add(12, "months").subtract(1, "day").toDate()}
                            placeholderText="Expiration date"
                            disabled={isLoading}
                        />
                    </div>

                    <div>
                        <Button color="primary" type="submit" disabled={isLoading}>
                            <FontAwesomeIcon icon={faPlus} className="margin-right-xxs" /> Create
                        </Button>
                    </div>
                </Form>
            </div>

            {isLoading && <Loader />}

            {!isLoading && (
                <>
                    <ManipulationApiKeyConfirmation
                        action="create"
                        show={showCreateApiKeyConfirmationModal}
                        description={inputDescriptionValue}
                        cancel={() => setShowCreateApiKeyConfirmationModal(false)}
                        confirm={createApiKey}
                        isManipulating={isCreatingApiKey}
                    />
                    <CreatedApiKeyModal
                        createdApiKey={createdApiKey}
                        closeModal={async () => {
                            setCreatedApiKey(null);
                            clearInputsValues();
                            await getAllApiKeys();
                        }}
                    />
                    <ApiKeysList apiKeys={apiKeys} refresh={getAllApiKeys} />
                </>
            )}
        </div>
    );
};

export default ApiPage;
