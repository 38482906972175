import React, { useEffect, useState } from "react";
import { useInput } from "rooks";
import moment from "moment";
import ApiKeyListItem from "./ApiKeyListItem";
import { formatServerDate } from "../../../utils/formatHelper";
import { ApiKey } from "client/src/models/apiKeysModel";

interface Props {
    apiKeys: ApiKey[];
    refresh: () => Promise<void>;
}

const ApiKeysList = ({ apiKeys, refresh }: Props) => {
    if (!apiKeys || apiKeys.length === 0) {
        return null;
    }

    const [filteredApiKeys, setFilteredApiKeys] = useState<ApiKey[]>([]);
    const inputSearch = useInput<string>("");

    useEffect(() => {
        const activeApiKeys = apiKeys
            .filter((apiKey) => apiKey.status === "Active")
            .toSorted((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());

        const notActiveApiKeys = apiKeys
            .filter((apiKey) => apiKey.status !== "Active")
            .toSorted((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());

        setFilteredApiKeys(
            [...activeApiKeys, ...notActiveApiKeys].filter((apiKey) =>
                `${apiKey.description} ${formatServerDate(apiKey.expiresAt, "YYYY-MM-DD")} ${formatServerDate(apiKey.createdAt)} ${apiKey.status}`
                    .toLowerCase()
                    .includes(inputSearch.value.toLowerCase())
            )
        );
    }, [apiKeys, inputSearch.value]);

    return (
        <div className="api-keys-list">
            <div className="api-keys-list-header">
                <h3>API Keys</h3>
                <div className="flex-horizontal gap-3">
                    <input {...inputSearch} placeholder="Search (Description, Dates, Status)" />
                </div>
            </div>
            {filteredApiKeys.length > 0 ? (
                filteredApiKeys.map((apiKey: ApiKey) => (
                    <ApiKeyListItem key={apiKey.id} apiKey={apiKey} refresh={refresh} />
                ))
            ) : (
                <div className="d-flex justify-content-center align-items-center">
                    <h2 className="mt-5 text-secondary">No API keys found...</h2>
                </div>
            )}
        </div>
    );
};

export default ApiKeysList;
