import React, { ReactNode } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "../../../hooks/useRouter";
import { ROUTES } from "../../../utils/Routes";

const logo = require("../../../../img/logo.svg");

interface Props {
    children: ReactNode;
}

interface NavigationItem {
    displayName: string;
    path: (typeof ROUTES)[keyof typeof ROUTES];
}

const navigationItems: NavigationItem[] = [
    {
        displayName: "API",
        path: ROUTES.api,
    },
    {
        displayName: "Main Page",
        path: ROUTES.main,
    },
];

const TopBar = ({ children }: Props) => {
    const { userInfo, logout } = useAuth();

    const router = useRouter();

    return (
        <>
            <div className="top-bar">
                <div>
                    <Link to="/">
                        <img className="logo" src={logo} alt="RavenDb logo" />
                    </Link>
                </div>
                <div>
                    {userInfo.isAuthenticated ? (
                        <div className="flex-horizontal align-items-center">
                            <span className="margin-right">{userInfo.email}</span>

                            {navigationItems.map((item) => {
                                if (router.pathname === item.path) return;

                                return (
                                    <a
                                        key={item.path}
                                        onClick={() => router.push(item.path)}
                                        className="link margin-right"
                                    >
                                        {item.displayName}
                                    </a>
                                );
                            })}

                            <a onClick={logout} className="link">
                                <FontAwesomeIcon icon={faArrowRightFromBracket} className="margin-right-xxs" /> Logout
                            </a>
                        </div>
                    ) : (
                        <Link to="/isv" className="link">
                            <FontAwesomeIcon icon={faArrowRightToBracket} className="margin-right-xxs" /> Login
                        </Link>
                    )}
                </div>
            </div>

            {children}
        </>
    );
};

export default TopBar;
